import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Autoplay } from "swiper";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import logo from "../../../images/corona-island/logo-coronaisland.svg";
import styles from "./section.module.scss";
import "../../../styles/corona-island.scss";

// Import Swiper styles
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";
import "swiper/swiper-vars.scss";
import "swiper/swiper.scss";

SwiperCore.use([Navigation, Autoplay])

const SectionB = () => {

  const data = [
    {
      title: 'El lugar',
      subTitle: 'La única manera de llegar a nuestro paraíso natural es en lancha, desde la hermosa ciudad de Cartagena.',
      class: styles.swiper1
    },
    {
      title: 'Biodiversidad',
      subTitle: 'Cuando decimos que es un paraíso 100% natural, lo decimos en serio. Te encontrarás con monos, tucanes, loros, papagayos. Obviamente toda la arquitectura del lugar fue pensada para no modificar nada y que los animales puedan vivir libremente.',
      class: styles.swiper2
    },
    {
      title: 'Actividades',
      subTitle: 'Empezá el día haciendo kayaks o stand up padlle. En la tarde podés disfrutar de la cancha de voley, los sunsets y de los variados espacios para relajarse como el spa, el espacio chill o las clases de yoga.',
      class: styles.swiper3
    },
    {
      title: 'Sustentabilidad',
      subTitle: 'Toda la arquitectura de la isla es 100% sustentable y libre de plástico certificada por Ocean Globe. También podrás disfrutar de actividades sustentables como la reforestación de manglares típicos de la zona y el cuidado de corales.',
      class: styles.swiper4
    },
    {
      title: 'Bungalow',
      subTitle: 'Venir a la isla es venir a vivir en espacios pensados para relajarte con una arquitectura sustentable, cabañas al mar y vistas inigualables del atardecer.',
      class: styles.swiper5
    },
    {
      title: 'La Gastronomía',
      subTitle: 'Todo nuestro menú está inspirado en la naturaleza y el atardecer. Además, fue especialmente elaborado por el reconocido chef Christopher Carpentier.',
      class: styles.swiper6
    },
  ];

  return (
    <section id='card-section' className={styles.sectionB}>

      <img src={logo} alt='logo' className={styles.logo}/>

      <div className={styles.row}>
        <article className={styles.info}>
          <h1>CONOCÉ <br/> CORONA <br/> ISLAND</h1>
          <p>
            *Nuestro paraíso se encuentra en <br/>
            construcción, las imágenes son referenciales.
          </p>
          <div className={`next ${styles.next}`} />
        </article>

        <Swiper
          spaceBetween={17}
          slidesPerView='auto'
          loop={true}
          autoplay={{
            delay: 4000,
            disableOnInteraction: false,
          }}
          navigation={{
            nextEl: `.next`,
          }}
          className={styles.swiperContainer}
          id='swiper-gradient'
        >
          {data.map(item => {
            return (
              <SwiperSlide 
                key={`Swiper img ${item.class}`}
                className={`${styles.class} ${item.class}`}
              >
                <div>
                  <h1>{item.title}</h1>
                  <p>{item.subTitle}</p>
                </div>
              </SwiperSlide>
            )
          })}
        </Swiper>
      </div>

      <article className={styles.banner}>
        <div className={styles.blur}></div>
        <h1 >DE LA NATURALEZA <br/>A LA BOTELLA</h1>
        <h2 >Una cerveza para disfrutar de los paraísos naturales de la  <br/>tierra desde donde estés.</h2>
        <a
          href="https://www.tada.com.ar/catalogo/grupo/corona"
          target='_blank'
        >
          COMPRA AHORA
        </a>
      </article>
    </section>
  );
};

export default SectionB;
