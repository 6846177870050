import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Autoplay } from "swiper";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import swa1 from "../../../images/corona-island/swiper-a-1.jpg";
import swa2 from "../../../images/corona-island/banner-home-2.jpg";
import swa3 from "../../../images/corona-island/swiper-a-3.jpg";
import swa4 from "../../../images/corona-island/banner-home-3.jpg";
import swa5 from "../../../images/corona-island/swiper-a-5.jpg";
import swa6 from "../../../images/corona-island/swiper-a-6.png";
import styles from "./section.module.scss";

// Import Swiper styles
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";
import "swiper/swiper-vars.scss";
import "swiper/swiper.scss";

SwiperCore.use([Navigation, Autoplay])

const SectionA = () => {

  const data = [
    {
      img: swa1,
    },
    {
      img: swa2,
    },
    {
      img: swa3,
    },
    {
      img: swa4,
    },
    {
      img: swa5,
    },
    {
      img: swa6,
    }
  ];

  return (
    <section id='video-section' className={styles.sectionA}>

      <div className={styles.cardVideo}>
        <div className={styles.videoContainer}>
          <iframe
            className={`${styles.video}`}
            src={`https://www.youtube.com/embed/3BS3d0Kaf98`}
            title="YouTube video player"
            frameborder="0"
            allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
        <div className={styles.videoText}>
          <h1>CORONA ISLAND</h1>
          <p>
            Un paraíso en medio del caribe, en donde la arquitectura se integra con el
            paisaje. Un lugar donde podrás disfrutar de las experiencias únicas que te 
            conectarán con la naturaleza, y vivir los mejores aterdeceres.
          </p>
          <AniLink
            to="/islacorona/form"
            className={styles.btn}
          >
            REGISTRATE Y PARTICIPÁ
          </AniLink>
        </div>
        
      </div>
      <Swiper
        spaceBetween={10}
        slidesPerView='auto'
        loop={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        navigation={{
          nextEl: `.next`,
        }}
        className={styles.swiperContainer}
      >
        {data.map((item, i) => {
          return (
            <SwiperSlide 
              key={`Swiper img ${i}`}
              className={styles.slide}
            >
              <img src={item.img} alt='img' />
            </SwiperSlide>
          )
        })}
        <div className={`next ${styles.next}`} />
      </Swiper>
    </section>
  );
};

export default SectionA;
