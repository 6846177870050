import React, { useEffect, useState } from "react"
import SEO from "../../components/seo"
import Layout from "../../components/winter-2022/Layout"
import SwiperBanners from "../../components/winter-2022/SliderBanner"
import SectionA from "../../components/winter-2022/SectionA"
import SectionB from "../../components/winter-2022/SectionB"
import AgeGate2 from "../../components/agegate"
import "../../styles/Global.css"
import "../../styles/layout.module.css"

const Winter2022 = () => {
    const [age, setAge] = useState(true)

    useEffect(() => {
        let local = sessionStorage.getItem("age")
        if (local) setAge(false)
    }, [])

    useEffect(() => {
        window.dataLayer.push({
            brand: "Corona",
            Zone: "SAZ",
            country: "ARG",
            city: "CABA",
            campaign: `${
                window.location.search.includes("?cmp=")
                    ? window.location.search.split("?cmp=")[1].split("&")[0]
                    : ""
            }`,
            cms: "0",
            event: "pageView",
            language: "es",
            login: false,
            pageName: "Isla Corona",
            pageType: "Content Page",
            SiteType: "Brand",
            product: "NA",
            sku: "NA",
            userUid: "",
            url: "https://www.cervezacorona.com.ar/islacorona",
            url_campaign: `${
                window.location.search.includes("utm_campaign=")
                    ? window.location.search.split("utm_campaign=")[1]
                    : ""
            }`,
            Step_number: "",
        })
    }, [])

    return !age ? (
        <Layout>
            <SEO title="ISLA CORONA 2022" />
            <SwiperBanners />
            <SectionA />
            <SectionB />
        </Layout>
    ) : (
        <AgeGate2 setAge={setAge} />
    )
}

export default Winter2022
